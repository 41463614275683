<template>
    <v-list-item-content>            
        <v-text-field 
            v-if="type==='text'"
            :label="name"
            v-model="newValue"
            :messages="description ? description + ' [Text]' : '[Text]'"
            :disabled="stray"
            clearable
        />

        <v-combobox 
            v-if="type==='tagsingle' && valuelisttype==='predefined'"
            :label="name"      
            :messages="description ? description + ' [Tag - Single]' : '[Tag - Single]'"       
            v-model="newValue"
            :items="valuelist"
            :disabled="stray"
            chips
            clearable
        />
        <v-combobox 
            v-if="type==='tagsingle' && valuelisttype==='free'"
            :label="name" 
            :messages="description ? description + ' [Tag - Single]' : '[Tag - Single]'"       
            v-model="newValue"
            :disabled="stray"
            chips
            clearable
        />

        <v-combobox 
            v-if="type==='tagmultiple' && valuelisttype==='predefined'"
            :label="name" 
            :messages="description ? description + ' [Tag - Multiple]' : '[Tag - Multiple]'"       
            v-model="newValue"
            :items="valuelist"
            :disabled="stray"
            multiple
            chips
            clearable
        />
        <v-combobox 
            v-if="type==='tagmultiple' && valuelisttype==='free'"
            :label="name" 
            :messages="description ? description + ' [Tag - Multiple]' : '[Tag - Multiple]'"
            v-model="newValue" 
            :disabled="stray"
            multiple     
            chips    
            clearable              
        />

        <v-combobox 
            v-if="type==='number'"
            :label="name" 
            :messages="description ? description + ' [Number]' : '[Number]'"
            v-model.number="newValue"
            type="number"
            :disabled="stray"
            clearable
        />
        <!-- <v-text-field 
            v-if="type==='date'"
            :label="(description || name) + ' (Date)'" 
            v-model="newValue"
        /> -->
        <v-menu
                v-if="type==='date'"
                ref="menuDatepicker"
                v-model="menuDatepicker"
                :close-on-content-click="false"
                
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on, attrs}">
                    <v-text-field
                        :label="name"
                        :messages="description ? description + ' [Date]' : '[Date]'"
                        v-model="newValueFormatted"                                              
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details    
                        :clearable="true"    
                        @click:clear="onClear"  
                        :disabled="stray"              
                    ></v-text-field>
                </template>
                <v-date-picker v-model="newValue" no-title scrollable @input="onInput" @emptied="onInput">                    
                </v-date-picker>
            </v-menu>
    </v-list-item-content>
</template>

<script>
import {parseDate, formatDate} from '@/core/date-utils.js'

export default {
    name: 'AttributeEditField',
    emits: ['update:title'],
    props: {        
        name: String,
        type: String,
        parent: String,
        hierarchy: String,
        valuelist: Array,
        description: String,
        defaultvalue: Object,
        valuelisttype: String,
        ingestionsource: String,
        ingestionconnector: String,
        stray: Boolean, 
        value: [String, Number, Date, Array],                             
    },
    // model: {
    //   prop: 'value',
    //   event: 'update'
    // },
    data() {
        return {
            newValue: this.value,
            newValueFormatted: "",
            menuDatepicker: false            
        }
    },
    watch: {
      newValue(val) {        
          //console.log("SEARCH DATE CALLED");
        // const t = this.formatDate(this.search_date);
        // console.log(t);
        if(this.type==="date") {
            this.newValueFormatted = formatDate(val);
        }                
        this.$emit('input', val);
      },
    },
    methods: {
        onInput(event) {
            this.menuDatepicker = false;
        },
        onClear(event) {
            this.newValue = "";
        }
    },
    parseDate(d) {
        return parseDate(d);
    },
    formatDate(d) {
        return formatDate(d);
    },
}
</script>

<style lang="scss" scoped>
    
</style>